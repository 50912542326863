import moment from "moment";
import { useEffect, useState } from "react";
import { Drawer, Input } from "antd";
import crossIcon from "../../../../assets/cross.svg";
import UserActivityStatus from "../../../../components/user-activity-status/user-activity-status";
import encodeSvgWithColor from "../../../../common/utils/encodeSvgWithColor";
import ApiSubjectsManagement from "../../../../api/subjects-manager";

const Quiz = ({ items = [], onChange, isEdit }) => {
  const [images, setImages] = useState({});

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const imageLinks = {};
        for (const item of items) {
          if (item.type === "photoPicker" && item.text) {
            const response = await ApiSubjectsManagement.getSubjectUpload({ uploadId: item.text });
            imageLinks[item.text] = response.data.signedUrl;
          }
        }
        setImages(imageLinks);
      } catch (error) {
        console.error("Upload error:", error);
      }
    };

    fetchImages();
  }, [items]);

  const view = items.map(({ text, questionText, id, type }) => {
    let formattedAnswer;
    if (type === "photoPicker" && images[text]) {
      formattedAnswer = <img src={images[text]} alt="Uploaded" className="photo-preview" />;
    } else {
      formattedAnswer = Array.isArray(text) ? (
        <ul>{text.map((item, index) => <li key={index}>{item}</li>)}</ul>
      ) : (
        text || "N/A"
      );
    }

    return (
      <div key={id} className="survey__drawer__form-item">
        <p className="survey__drawer__form-item-label w250">
          {questionText || "N/A"}
        </p>
        <p>{formattedAnswer}</p>
      </div>
    );
  });

  const edit = items.map(({ text, questionText, id }) => {
    const formattedAnswer = Array.isArray(text) ? text.join(", ") : text || "N/A";
    return (
      <div key={id} className="survey__drawer__form-item">
        <p className="survey__drawer__form-item-label w250">
          {questionText || "N/A"}
        </p>
        <Input defaultValue={formattedAnswer} onChange={onChange} maxLength={1000} />
      </div>
    )
  });

  return isEdit ? edit : view;
};

const TaskDrawer = ({ isOpenDrawer, close, task }) => {
  const { response, completedAt, status, dueDate } = task || {};
  const { backgroundColor, avatar, avatarColor, title, description } =
    task?.WeeklySchedule?.DailyTask || {};
  console.log('---TASK response---', response)
  return (
    <Drawer open={isOpenDrawer} width={600} closable={false} onClose={close}>
      <div className="task-drawer__header">
        {avatar ? (
          <div
            className="icon-wrapper"
            style={{
              backgroundColor,
            }}
          >
            <img src={encodeSvgWithColor(avatar, avatarColor)} alt="avatar" />
          </div>
        ) : (
          <div />
        )}
        <div className="task-drawer__header__right">
          <UserActivityStatus userStatus={status || "PENDING"} />
          <img src={crossIcon} alt="close" onClick={close} />
        </div>
      </div>
      <div>
        <p className="task-drawer__title">{title}</p>
        <p className="task-drawer__description">{description}</p>
        <div className="task-drawer__history-item-container">
          <p className="task-drawer__history-item__title">Due date</p>
          <p className="task-drawer__history-item__result">
            {moment(dueDate).utc().format("DD MMM YYYY, HH:mm")}, UTC
          </p>
        </div>
        {completedAt && (
          <div className="task-drawer__history-item-container">
            <div>
              <div className="border-bottom-1">
                <p className="task-drawer__history-item__title">Completed</p>
                <p className="task-drawer__history-item__result  mb10">
                  {moment(completedAt).utc().format("DD MMM YYYY, HH:mm")}, UTC
                </p>
              </div>
              {/* {headerQuizButtons} */}
            </div>
            <div>
              <Quiz items={response} />
            </div>
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default TaskDrawer;
