import { useEffect, useMemo, useState } from "react";
import Profile from "./profile/profile";
import ApiSubjectsManagement from "../../../api/subjects-manager";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Surveys from "./surveys/surveys";
import SubjectTasks from "./tasks/tasks";
import { MainLayoutV2 } from "../../../components/main-layout-v2/main-layout-v2";
import { Sidebar } from "../../../components/sidebar/sidebar";
import { MainNavigation } from "../../../components/main-navigation/main-navigation";
import HealthData from "./health/health";
import Uploads from "./uploads/uploads";
import moment from "moment";
import { selectIsSystemAdmin, selectIsAdmin } from "../../../store/slices/user";

const SubjectLayout = () => {
  const isSystemAdmin = useSelector(selectIsSystemAdmin);
  const isAdmin = useSelector(selectIsAdmin);
  const defaultStartDate = moment().subtract(30, "days").utc().toISOString();
  const defaultEndDate = moment().utc().toISOString();
  const { pathname, hash } = useLocation();
  const { id } = useParams();

  const [subject, setSubject] = useState();
  const [survey, setSurvey] = useState();
  const [tasks, setTasks] = useState();
  const [analytics, setAnalytics] = useState();
  const [titles, setTitles] = useState();
  const [filters, setFilters] = useState({
    period: [{ start: defaultStartDate, end: defaultEndDate }]
  });
  const navigate = useNavigate();

  const getSubject = () => {
    ApiSubjectsManagement.getSubject(subject?.id || id).then((r) => {
      setSubject(r.data.subject);
    });
  };

  const getSurvey = () => {
    ApiSubjectsManagement.getSubjectSurveys(subject.id || id).then((r) => {
      setSurvey(r.data);
    });
  };

  const getSubjectTasks = () => {
    const params = {
      ...filters,
      startDueDate: filters.period?.[filters.period.length - 1]?.start,
      endDueDate: filters.period?.[filters.period.length - 1]?.end,
    };
    ApiSubjectsManagement.getSubjectTasks(subject.id || id, params).then(
      (r) => {
        setTasks(r.data.subjectTasks);
        setAnalytics(r.data.analytics);
        setTitles(r.data.titles);
      }
    );
  };

  useEffect(() => {
    getSubject();
  }, [id]);

  useEffect(() => {
    if (subject?.id) {
      getSurvey();
    }
  }, [subject?.id]);

  useEffect(() => {
    if (subject?.id) {
      getSubjectTasks();
    }
  }, [subject?.id, filters]);

  const updateFilterValue = (filter) => {
    setFilters(filter);
  };

  const leftSideNavItems = [
    {
      key: `${pathname}#profile`,
      label: "Profile data",
      default: true,
    },
    {
      key: `${pathname}#tasks`,
      label: "Tasks",
    },
    {
      key: `${pathname}#surveys`,
      label: "Onboarding",
    },
    {
      key: `${pathname}#health`,
      label: "Health",
    },
    {
      key: `${pathname}#uploads`,
      label: "Uploads",
    },
  ];

  const content = useMemo(() => {
    if (!subject) return;

    switch (hash) {
      default:
      case "#profile":
        return <Profile subject={subject} fetchSubject={getSubject} isSystemAdmin={isSystemAdmin} isAdmin={isAdmin}/>;
      case "#surveys":
        return <Surveys survey={survey} />;
      case "#tasks":
        return (
          <SubjectTasks
            tasks={tasks}
            analytics={analytics}
            updateFilterValue={updateFilterValue}
            titles={titles}
            planWeek={subject.weekIndex + 1}
            currentWeek={subject.currentWeek}
            filters={filters}
          />
        );
      case "#health":
        return <HealthData />;
      case "#uploads":
        return <Uploads />;
    }
  }, [hash, subject, survey, tasks, analytics]);

  return (
    <MainLayoutV2>
      <MainNavigation />
      <Sidebar
        items={leftSideNavItems}
        onBack={() => {
          navigate("/subject-management");
        }}
        title={`LF-${subject?.displayId || "000"}`}
      />
      {content}
    </MainLayoutV2>
  );
};

export default SubjectLayout;
