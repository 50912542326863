import SubjectDashboard from "../subjects-management/dashboard/subject-dashboard";
import { MainLayoutV2 } from "../../components/main-layout-v2/main-layout-v2";
import { MainNavigation } from "../../components/main-navigation/main-navigation";
import { Body } from "../../components/main-body/body";
import { useEffect, useMemo, useState } from "react";
import ApiSubjectsDashboard from "../../api/subjects-dashboard";
import DropdownFilters from "../../components/dropdown-filters/dropdown-filters";
import moment from "moment";

const Dashboard = () => {
  const [cohortsList, setCohortsList] = useState([]);
  // const [dashboardFilter, setDashboardFilter] = useState();

  const getDashboardFilterItems = () => {
    ApiSubjectsDashboard.filterField().then((r) => {
      setCohortsList(r.data.cohorts?.map((c) => ({ id: c.id, name: c.value })));
    });
  };

  useEffect(() => {
    getDashboardFilterItems();
  }, []);

  const defaultStartDate = moment().subtract(31, "days").utc().toISOString();
const defaultEndDate = moment().subtract(1, "day").endOf("day").utc().toISOString();

  const filterItems = [
    { name: "Cohorts", id: "cohortIds", children: cohortsList },
    { 
      name: "Period",
      id: "period",
      period: true,
      defaultValue: [{ start: defaultStartDate, end: defaultEndDate }],
    },
  ];

  const [dashboardFilter, setDashboardFilter] = useState({
    period: [{ start: defaultStartDate, end: defaultEndDate }],
  });

  const updateFilterValue = (value) => {
    setDashboardFilter(value);
  };

  const headerFilter = useMemo(
    () => (
      <DropdownFilters
        filtersBy={filterItems}
        updateFilterValue={updateFilterValue}
        filter={dashboardFilter}
      />
    ),
    [cohortsList]
  );

  return (
    <MainLayoutV2>
      <MainNavigation />
      <Body
        scroll
        header={{ title: "Summary dashboard", isBold: true }}
        filters={headerFilter}
      >
        <SubjectDashboard filterOptions={dashboardFilter} />
      </Body>
    </MainLayoutV2>
  );
};

export default Dashboard;
