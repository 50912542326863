import {
  Button,
  Divider,
  Dropdown,
  Input,
  Select,
  Switch,
  TimePicker,
} from "antd";
import classNames from "classnames";
import React, { useEffect, useMemo, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import ApiTaskManagement from "../../../../api/task-managements";
import useSearch from "../../user-management/useSearch";
import { Chips } from "../../../../components/chips/chips";
import { Icon } from "../../../../components/icon/icon";
import ApiPlanManagement from "../../../../api/plan-management";
import dayjs from "dayjs";
import TaskCard from "../../task-management/task-card/task-card";
import { SearchOutlined } from "@ant-design/icons";
import encodeSvgWithColor from "../../../../common/utils/encodeSvgWithColor";
import PageContentLoader from "../../../../components/loader/page-content-loader";

const DAYS = ["su", "mo", "tu", "we", "th", "fr", "sa"];

const LOCK_TYPES = [
  { key: "never", label: "Never"},
  { key: "time", label: "On schedule"},
  { key: "timeout", label: "After timeout"},
  { key: "nextScenario", label: "When next arrives"}
]

const TaskDropdownComponent = ({
  children,
  task,
  planned,
  locked,
  lockType,
  day,
  activityId,
  replaceTaskToDay,
  replaceScenarioToLock,
  deleteTask,
  updateTaskNotificationTime,
  updateScenarioLockTime,
  tasksList,
  categories,
  includeInDays,
  updateReschedule,
  rescheduling,
  updateDuration,
  duration,
  onOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const { avatar, avatarColor, title, backgroundColor, name } = task;
  const { remindHour, remindMinute } = planned;
  const { lockDays, lockHours, lockMinutes } = locked;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState();
  // const [daysToAdd, setDaysToAdd] = useState(0);
  
  const close = () => {
    setIsOpen(false);
    onClose();
  };

  const handleSelectTask = () => {
    const selectTask = tasksList.find((t) => t.DailyTask.id === task.id);
    if (selectTask) {
      setSelectedTask(selectTask?.DailyTask);
      setIsOpen(true);
      onOpen();   
    } else if (task) {
      const scenarioUrl = `/administration/scenarios/${task.id}`;
      window.open(scenarioUrl, '_blank');
    }
  };

  const disabledHours = () => {
    const hours = [];
    if (lockType === 'timeout') {
      return hours
    }
    for (let i = 0; i < remindHour; i++) {
      hours.push(i);
    }
    return hours;
  };

  const taskContentRender = useMemo(() => {
    return (
      <div className="calendar-task-modal-container">
        <div className="calendar-task-modal-header">
          <div
            className="calendar-task-modal-header-right"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {avatar ? (
              <div
                className="icon-wrapper"
                style={{
                  backgroundColor,
                }}
              >
                <img
                  src={encodeSvgWithColor(avatar, avatarColor)}
                  alt="avatar"
                />
              </div>
            ) : (
              <div
                className="icon-wrapper"
                style={{
                  display: 'none',
                }}
              />
            )}
            <div
              style={{
                marginLeft: avatar ? '10px' : '0',
                textAlign: avatar ? 'left' : 'left',
                width: avatar ? 'auto' : '100%',
              }}
            >
              <p className="calendar-task-modal-header-title">{title}</p>
            </div>
          </div>
          <div />
          <Button
            icon={<Icon name={"arrow-right"} />}
            style={{ background: "#fff", boxShadow: "none" }}
            onClick={handleSelectTask}
          />
        </div>
        {name && (
          <div className="calendar-task-modal-header">
            <div className="calendar-task-modal-header-right">
              <div>
                <p className="calendar-task-modal-header-title">{name}</p>
              </div>
            </div>
          </div>
        )}
        <div className="calendar-task-modal-body">
          <div className="calendar-task-modal-field">
            <p className="calendar-task-modal-field-title">
              {t("plan_management.task_modal.day")}
            </p>
            <Select
              options={DAYS.map((d) => ({
                key: d,
                label: t(`days.${d}`),
                value: d,
                disabled: includeInDays.includes(d),
              }))}
              bordered={false}
              defaultValue={{
                key: day,
                label: t(`days.${day}`),
              }}
              style={{ marginLeft: -11, width: 138 }}
              onChange={(value) => replaceTaskToDay(value, activityId)}
            />
          </div>
          <div className="calendar-task-modal-field">
            <p className="calendar-task-modal-field-title">
              {t("plan_management.task_modal.time")}
            </p>
            <TimePicker
              format={"HH:mm"}
              bordered={false}
              style={{ marginLeft: -11, width: 140 }}
              defaultValue={
                Number.isInteger(remindHour) && Number.isInteger(remindMinute)
                  ? dayjs()
                      .hour(remindHour || 8)
                      .minute(remindMinute || 0)
                  : null
              }
              onChange={(value) =>
                updateTaskNotificationTime(value, activityId)
              }
            />
          </div>
          {task.title && avatar && (
            <div className="calendar-task-modal-field">
              <p className="calendar-task-modal-field-title">
                {t("plan_management.task_modal.reschedual")}
              </p>
              <Switch
                defaultChecked={rescheduling}
                onChange={(value) => updateReschedule(value, activityId)}
                size="small"
                disabled={duration > 1}
              />
            </div>
          )}
          { !avatar && (
            <div className="calendar-task-modal-field calendar-task-modal-field-lock-type">
              <p className="calendar-task-modal-field-title">
                {t("plan_management.task_modal.lock")}
              </p>
              <Select
                options={LOCK_TYPES.map((l) => ({
                  key: l.key,
                  label: l.label,
                  value: l.key,
                  // disabled: includeInDays.includes(d),
                }))}
                bordered={false}
                dropdownMatchSelectWidth={false}
                defaultValue={{
                  key: lockType || LOCK_TYPES[0].key,
                  label: LOCK_TYPES.find(x => x.key === lockType)?.label || LOCK_TYPES[0].label,
                }}
                style={{ marginLeft: -11, minWidth: 138 }}
                onChange={(value) => replaceScenarioToLock(value, activityId)}
              />
            </div>
          )}
          {!avatar && 'time' === lockType && (
            <div className="calendar-task-modal-field">
              <p className="calendar-task-modal-field-title">
                {t(`plan_management.task_modal.time`)}
              </p>
              <TimePicker
                disabledHours={disabledHours}
                format={"HH:mm"}
                bordered={false}
                style={{ marginLeft: -11, width: 140 }}
                defaultValue={
                  Number.isInteger(lockHours) && Number.isInteger(lockMinutes)
                    ? dayjs()
                        .hour(lockHours)
                        .minute(lockMinutes)
                    : null
                }
                onChange={(value) =>
                  updateScenarioLockTime(value.$H, value.$m, null, activityId)
                }
              />
            </div>
          )}

          {!avatar && 'timeout' === lockType && (
            <div className="calendar-task-modal-field">
              <p className="calendar-task-modal-field-title">
                {t(`plan_management.task_modal.timeout`)}
              </p>
              
              <Select
                defaultValue={lockDays || 0}
                onChange={(days) => {
                  // setDaysToAdd(days)
                  updateScenarioLockTime(lockHours, lockMinutes, days, activityId);
                }}
                bordered={false}
                style={{ width: 75, marginRight: 10, marginLeft: -11 }}
                dropdownStyle={{ overflowY: 'auto' }}
              >
                {[...Array(31).keys()].map(day => (
                  <Select.Option key={day} value={day}>
                    {day}d
                  </Select.Option>
                ))}
              </Select>

              <TimePicker
                disabledHours={disabledHours}
                format={"HH:mm"}
                bordered={false}
                style={{ marginLeft: -11 }}

                defaultValue={
                  Number.isInteger(lockHours) && Number.isInteger(lockMinutes)
                    ? dayjs()
                        .hour(lockHours)
                        .minute(lockMinutes)
                    : null
                }
                onChange={(value) => {
                  updateScenarioLockTime(value.$H, value.$m, lockDays, activityId);
                }}
              />
            </div>
          )}
          {task.title && avatar && (
            <div className="calendar-task-modal-field">
              <p className="calendar-task-modal-field-title">
                {t("plan_management.task_modal.duration")}
              </p>
              <Select
                defaultValue={duration}
                onChange={(value) => updateDuration(value, activityId)}
                bordered={false}
                style={{ marginLeft: -11, width: 100 }}
                disabled={rescheduling}
              >
                {[...Array(99).keys()].map((period) => (
                  <Select.Option key={period + 1} value={period + 1}>
                    {period + 1}
                  </Select.Option>
                ))}
              </Select>
            </div>
          )}
        </div>
        <div className="calendar-task-modal-footer">
          <Button
            icon={<Icon name={"delete"} />}
            style={{ marginRight: 16, background: "#fff", boxShadow: "none" }}
            onClick={() => deleteTask(activityId)}
          >
            {t(
              task.title && avatar
                ? "plan_management.task_modal.remove_task"
                : "plan_management.task_modal.remove_scenario"
            )}
          </Button>
        </div>
      </div>
    );
  }, [task, day, planned, activityId]);

    return (
      <>
        <TaskCard
          isOpen={isOpen}
          close={close}
          task={selectedTask}
          categories={categories}
          onlyView={true}
        />
        <Dropdown
          dropdownRender={() => taskContentRender}
          trigger={["click"]}
          overlayStyle={{ width: "max-content", zIndex: 100 }}
        >
          {children}
        </Dropdown>
      </>
    );
  };

const AddItemComponent = ({ items, onClick, handleAddTask, isScenario }) => {
  const { search, handleUpdateSearchValue } = useSearch();

  const list = useMemo(() => {
    const availableTasks = search
      ? items.filter((item) =>
          (item.DailyTask ? item.DailyTask.title : item.name || item.title)
            ?.toLowerCase()
            .includes(search.toLowerCase())
        )
      : items;

    return availableTasks.map((item, index) => {
      const { avatar, avatarColor, backgroundColor, title, name, id } =
        item.DailyTask || item;

      return {
        key: id + index,
        label: title ?? name,
        icon: (avatar || backgroundColor) && (
          <div
            className="calendar-icon-wrapper"
            style={{
              backgroundColor,
            }}
          >
            {avatar && (
              <img
                style={{ width: 12 }}
                src={encodeSvgWithColor(avatar, avatarColor)}
                alt="avatar"
              />
            )}
          </div>
        ),
        onClick: () => handleAddTask(item),
      };
    });
  }, [items, search]);

  const dropdownRender = (content, isScenario) => {
    return (
      <div
        style={{
          padding: "0 10px",
          backgroundColor: "transparent",
        }}
      >
        <div
          className="ant-dropdown ant-dropdown-menu"
          style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
        >
          <div style={{ display: "flex", marginLeft: 15, padding: "3px" }}>
            <SearchOutlined />
            <Input
              bordered={false}
              value={search}
              placeholder={'Search ' + (isScenario ? 'scenario' : 'task')}
              onChange={handleUpdateSearchValue}
            />
          </div>
          <Divider style={{ margin: "0 0 10px 0" }} />
          <div style={{ maxHeight: 260, overflow: "auto" }}>
            {React.cloneElement(content, { style: { boxShadow: "none" } })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Dropdown
      menu={{
        items: list,
        onClick,
      }}
      trigger={["click"]}
      overlayStyle={{
        width: "max-content",
      }}
      dropdownRender={(content) => dropdownRender(content, isScenario)}
    >
      <div>
        <Button
          type={"text"}
          iconName={"add"}
          backgroundColor={"shade"}
          fontSize={13}
          cursor={"pointer"}
          icon={<Icon name={"add"} color={"text-1"} />}
          style={{ color: "var(--text-1)", fontSize: 13, paddingLeft: 8 }}
        >
          {isScenario ? "Add scenario" : "Add task"}
        </Button>
      </div>
    </Dropdown>
  );
};

const CalendarTask = ({
  task,
  planned,
  locked,
  lockType,
  day,
  activityId,
  replaceTaskToDay,
  replaceScenarioToLock,
  deleteTask,
  updateTaskNotificationTime,
  updateScenarioLockTime,
  tasksList,
  categories,
  includeInDays,
  updateReschedule,
  updateDuration,
  rescheduling,
  duration,
  id,
  isScenario,
  setActiveId,
  className,
  style,
  showTitleAndTime,
  showLockIcon
}) => {
  const { backgroundColor, title, avatarColor, name } = task || {};
  const { remindHour, remindMinute } = planned;

  const time =
    Number.isInteger(remindHour) && Number.isInteger(remindMinute)
      ? `${remindHour}:${
          remindMinute < 10 ? `0${remindMinute}` : remindMinute
        } `
      : "";

  return (
    <TaskDropdownComponent
      task={task}
      planned={planned}
      lockType={lockType}
      locked={locked}
      day={day}
      activityId={activityId}
      replaceTaskToDay={replaceTaskToDay}
      replaceScenarioToLock={replaceScenarioToLock}
      deleteTask={deleteTask}
      updateTaskNotificationTime={updateTaskNotificationTime}
      updateScenarioLockTime={updateScenarioLockTime}
      tasksList={tasksList}
      categories={categories}
      includeInDays={includeInDays}
      updateReschedule={updateReschedule}
      updateDuration={updateDuration}
      rescheduling={rescheduling}
      duration={duration}
      onOpen={() => setActiveId(id)}
      onClose={() => setActiveId(undefined)}
      style={{ width: "100%", height: 40 }}
    >
      <div
        className={`${className}`}
        data-id={id || (title || name).replace(/ /g, "-")}
        style={{ marginBottom: 5, marginRight: 5, width: "100%", ...style }}
      >
<Chips
  text={
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        lineHeight: 1.2,
      }}
    >
      {showTitleAndTime && (
        <>
          <span style={{ fontWeight: 700, marginRight: 4 }}>{time}</span>
          <span
            style={{
              display: "inline-block",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "150px",
            }}
          >
            {title || name}
          </span>
        </>
      )}
    </div>
  }
  color={avatarColor ?? "#000000"}
  fontSize={13}
  backgroundColor={backgroundColor || "#EFEFEF"}
  borderColor={backgroundColor || "#EFEFEF"}
  iconName={showLockIcon && !rescheduling && "locked"}
  cursor
  style={{ width: "100%", height: 40, maxWidth: "200px" }}
/>
      </div>
    </TaskDropdownComponent>
  );
};

const PlanCalendar = ({
  planId,
  scenariosList,
  planTasks,
  addTaskToPlan,
  updateTaskPlan,
  planScenarios,
  addScenarioToPlan,
  updateScenariosPlan,
  refreshPlan,
  weekIndex,
  weeklySchedules
}) => {
  const weekIndexRef = useRef(weekIndex);
  useEffect(() => {
    weekIndexRef.current = weekIndex;
  }, [weekIndex]);

  const { t } = useTranslation();
  const [hoverColumnIndex, setHoverColumnIndex] = useState();
  const [tasksList, setTasksList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [id, setId] = useState(undefined);
  const [pauseHover, setPauseHoverd] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const getTasks = (params) => {
    ApiTaskManagement.getTasks(params).then(({ data }) => {
      const { taskManagements, categories } = data || {};

      setTasksList(taskManagements.map((task) => ({ DailyTask: task })));
      setCategories(categories);
    });
  };

  const highlightSameItems = (e) => {
    if (pauseHover) {
      return;
    }
    if (e.target.closest(".calendar-item")) {
      const _id = e.target.closest(".calendar-item").dataset.id;
      if (id !== _id) {
        setId(e.target.closest(".calendar-item").dataset.id);
      }
    } else if (id) {
      setId(undefined);
    }
  };

  useEffect(() => {
    getTasks({ planIds: [planId]});
  }, []);

  useEffect(() => {
    document.addEventListener("mouseover", highlightSameItems);
    return () => document.removeEventListener("mouseover", highlightSameItems);
  }, [id]);

  const toggleOpen = (value) => {
    setId(value);
    if (value) {
      setPauseHoverd(true);
      return;
    }
    setPauseHoverd(false);
  };

  const viewAddBtn = (index) => {
    setHoverColumnIndex(index);
  };

  const addTask = (task, index) => {
    setLoading(true);
    const addedTask = { ...task, day: DAYS[index], weekIndex: weekIndexRef.current };
    ApiPlanManagement.updateWeeklySchedule(planId, addedTask).then(() => {
      refreshPlan();
      setLoading(false);
    });
    addTaskToPlan(addedTask);
  };

  const addScenario = (scenario, index, dailyScenario = []) => {
    const addedScenario = { ...scenario, day: DAYS[index], weekIndex: weekIndexRef.current };

    const lastOfDailyScenarioTime =
      dailyScenario[dailyScenario.length - 1]?.remindHour;

    let remindHour = scenario.remindHour;

    if (!remindHour && Number.isInteger(lastOfDailyScenarioTime)) {
      remindHour =
        lastOfDailyScenarioTime < 23 ? lastOfDailyScenarioTime + 1 : 23;
    }

    const newScenario = {
      scenarioId: scenario.id,
      day: DAYS[index],
      Scenario: scenario,
      remind: true,
      remindHour: remindHour ?? 7,
      remindMinute: scenario.remindMinute ?? 0,
      weekIndex: weekIndexRef.current
    };
    ApiPlanManagement.updateWeeklySchedule(planId, newScenario).then(() => {
      refreshPlan();
    });

    addScenarioToPlan(addedScenario);
  };

  const deleteTask = (activityId) => {
    setLoading(true);
    ApiPlanManagement.deleteActivity(planId, activityId).then(() => {
      refreshPlan();
      setLoading(false);
    });
  };

  const deleteScenario = (activityId) => {
    ApiPlanManagement.deleteActivity(planId, activityId).then(() => {
      refreshPlan();
    });
  };

  const replaceTaskToDay = (newDay, activityId) => {
    const changedTask = planTasks.find(
      (p) => (p.id || p.DailyTask.id) === activityId
    );
    setLoading(true);
    ApiPlanManagement.updateWeeklySchedule(planId, {
      ...changedTask,
      day: newDay,
      weekIndex: weekIndexRef.current
    }).then(() => {
      refreshPlan();
      setLoading(false);
    });

    const newPlan = planTasks.map((task) =>
      task.id === activityId ? { ...task, day: newDay } : task
    );
    updateTaskPlan(newPlan);
  };

  const replaceScenarioToLock = (newType, activityId) => {
    const changedScenario = planScenarios.find((p) => p.id === activityId);
    ApiPlanManagement.updateWeeklySchedule(planId, {
      ...changedScenario,
      lockType: newType,
      lockHours: null,
      lockMinutes: null,
      weekIndex: weekIndexRef.current
    }).then(() => {
      refreshPlan();
    });

    const newPlan = planScenarios.map((scenario) =>
      scenario.id === activityId ? { ...scenario, lockType: newType, } : scenario
    );

    updateScenariosPlan(newPlan);
  };

  const replaceScenarioToDay = (newDay, activityId) => {
    const changedScenario = planScenarios.find((p) => p.id === activityId);
    ApiPlanManagement.updateWeeklySchedule(planId, {
      ...changedScenario,
      day: newDay,
      weekIndex: weekIndexRef.current
    }).then(() => {
      refreshPlan();
    });

    const newPlan = planScenarios.map((scenario) =>
      scenario.id === activityId ? { ...scenario, day: newDay } : scenario
    );

    updateScenariosPlan(newPlan);
  };

  const updateDuration = (duration, activityId) => {
    const changedTask = planTasks.find(
      (p) => (p.id || p.DailyTask.id) === activityId
    );
    setLoading(true);
    ApiPlanManagement.updateWeeklySchedule(planId, {
      ...changedTask,
      duration,
      weekIndex: weekIndexRef.current,
      day: changedTask.originalDay || changedTask.day,
    }).then(() => {
      refreshPlan();
      setLoading(false);
    });

    const newPlan = planTasks.map((task) =>
      task.id === activityId ? { ...task, duration } : task
    );
    updateTaskPlan(newPlan);
  };

  const updateReschedule = (rescheduling, activityId) => {
    const changedTask = planTasks.find(
      (p) => (p.id || p.DailyTask.id) === activityId
    );

    ApiPlanManagement.updateWeeklySchedule(planId, {
      ...changedTask,
      rescheduling,
      weekIndex: weekIndexRef.current
    }).then(() => {
      refreshPlan();
    });

    const newPlan = planTasks.map((task) =>
      task.id === activityId ? { ...task, rescheduling } : task
    );
    updateTaskPlan(newPlan);
  };

  const updateScenarioReschedule = (rescheduling, activityId) => {
    const changedScenario = planScenarios.find((p) => p.id === activityId);

    ApiPlanManagement.updateWeeklySchedule(planId, {
      ...changedScenario,
      rescheduling,
      weekIndex: weekIndexRef.current
    }).then(() => {
      refreshPlan();
    });

    const newPlan = planScenarios.map((scenario) =>
      scenario.id === activityId ? { ...scenario, rescheduling } : scenario
    );

    updateScenariosPlan(newPlan);
  };

  const updateScenarioLockTime = (hours, minutes, days, activityId) => {
    // console.log('updateScenarioLockTime', time)
    const lockHours = hours + days*24;
    const lockMinutes = minutes;

    const changedScenario = planScenarios.find((p) => p.id === activityId);

    ApiPlanManagement.updateWeeklySchedule(planId, {
      ...changedScenario,
      lockHours,
      lockMinutes,
      weekIndex: weekIndexRef.current
    }).then(() => {
      refreshPlan();
    });

    const newPlan = planScenarios.map((scenario) =>
      scenario.id === activityId ? { ...scenario, lockHours, lockMinutes } : scenario
    );

    updateScenariosPlan(newPlan);
  }

  const updateTaskNotificationTime = (time, activityId) => {
    const remindHour = time.$H;
    const remindMinute = time.$m;

    const changedTask = planTasks.find(
      (p) => (p.id || p.DailyTask.id) === activityId
    );
    ApiPlanManagement.updateWeeklySchedule(planId, {
      ...changedTask,
      remind: true,
      remindHour,
      remindMinute,
      weekIndex: weekIndexRef.current
    }).then(() => {
      refreshPlan();
    });

    const newPlan = planTasks.map((task) =>
      task.id === activityId
        ? { ...task, remind: true, remindHour, remindMinute }
        : task
    );
    updateTaskPlan(newPlan);
  };

  const updateScenarioNotificationTime = (time, activityId) => {
    const remindHour = time.$H;
    const remindMinute = time.$m;

    const changedScenario = planScenarios.find((p) => p.id === activityId);
    ApiPlanManagement.updateWeeklySchedule(planId, {
      ...changedScenario,
      remind: true,
      remindHour,
      remindMinute,
      weekIndex: weekIndexRef.current
    }).then(() => {
      refreshPlan();
    });

    const newPlan = planScenarios.map((scenario) =>
      scenario.id === activityId
        ? { ...scenario, remind: true, remindHour, remindMinute }
        : scenario
    );

    updateScenariosPlan(newPlan);
  };

  const calendarDaysRender = useMemo(() => {
    const tasksForHandleSelect = (index) => {
      const todayPlannedTasks =
        planTasks?.filter((pt) => pt.day === DAYS[index]) || [];
      if (todayPlannedTasks.length) {
        return tasksList.filter(
          (t) =>
            !todayPlannedTasks.find(
              (task) => task.DailyTask.id === t.DailyTask.id
            )
        );
      }
      return tasksList;
    };

    const scenariosForHandleSelect = (index) => {
      const todayPlannedScenarios =
        planScenarios?.filter((pt) => pt.day === DAYS[index]) || [];
      if (todayPlannedScenarios.length) {
        return scenariosList.filter(
          (t) => !todayPlannedScenarios.find((scenario) => scenario.id === t.id)
        );
      }
      return scenariosList;
    };

    const showTitleAndTime = (task, dayIndex, weekIndex, totalWeeks) => {
      const isSingleDay = task.duration === 1;
      if (isSingleDay) return true;

      const startWeek = task.originalWeek;
      const startDayIndex = DAYS.indexOf(task.originalDay);
      const totalDays = task.duration;

      let endWeek = startWeek;
      let remainingDays = totalDays - 1;
      let currentDayIndex = startDayIndex;

      while (remainingDays > 0) {
        currentDayIndex = (currentDayIndex + 1) % 7;
        if (currentDayIndex === 0) {
          endWeek = (endWeek + 1) % totalWeeks;
        }
        remainingDays--;
      }

      const isFirstDayShown = weekIndex === startWeek && dayIndex === startDayIndex;
      const isWeekStart = dayIndex === 0;

      const isRecycledTask = startWeek !== weekIndex;
      return isFirstDayShown || (isWeekStart && isRecycledTask);
    };

    const showLockIcon = (task, dayIndex, weekIndex, totalWeeks) => {
      const isSingleDay = task.duration === 1;
      if (isSingleDay) return true;

      const startWeek = task.originalWeek;
      const startDayIndex = DAYS.indexOf(task.originalDay);
      const totalDays = task.duration;

      let endWeek = startWeek;
      let remainingDays = totalDays - 1;
      let currentDayIndex = startDayIndex;

      while (remainingDays > 0) {
        currentDayIndex = (currentDayIndex + 1) % 7;
        if (currentDayIndex === 0) {
          endWeek = (endWeek + 1) % totalWeeks;
        }
        remainingDays--;
      }

      const isSaturday = dayIndex === 6;
      if (totalDays <= totalWeeks * 7) {
        const isLastDayShown = weekIndex === endWeek && dayIndex === currentDayIndex;
        return isLastDayShown || isSaturday;
      }

      const cycleLength = totalWeeks * 7;
      const lastBeforeStartDayIndex = (startDayIndex + cycleLength - 1) % 7;
      const lastBeforeStartWeek = (startWeek + Math.floor((startDayIndex + cycleLength - 1) / 7)) % totalWeeks;

      const isLastBeforeStart = weekIndex === lastBeforeStartWeek && dayIndex === lastBeforeStartDayIndex;
      return isLastBeforeStart || isSaturday;
    };

    const getTaskClass = (task, dayIndex, weekIndex, totalWeeks) => {
      const isSingleDay = task.duration === 1;
      if (isSingleDay) return "";

      const startWeek = task.originalWeek;
      const startDayIndex = DAYS.indexOf(task.originalDay);
      const totalDays = task.duration;

      let endWeek = startWeek;
      let remainingDays = totalDays - 1;
      let currentDayIndex = startDayIndex;

      while (remainingDays > 0) {
        currentDayIndex = (currentDayIndex + 1) % 7;
        if (currentDayIndex === 0) {
          endWeek = (endWeek + 1) % totalWeeks;
        }
        remainingDays--;
      }

      const isContinued = 
        dayIndex === 0 &&
        !(weekIndex === startWeek && startDayIndex === 0);

      const isFirstDayShown = weekIndex === startWeek && dayIndex === startDayIndex;
      const isLastDayShown =
        weekIndex === endWeek &&
        dayIndex === currentDayIndex &&
        totalDays <= totalWeeks * 7;

      if (isContinued && isLastDayShown) return "calendar-item--continued-end";
      if (isContinued) return "calendar-item--continued";
      if (isFirstDayShown) return "calendar-item--start";
      if (isLastDayShown) return "calendar-item--end";
      return "calendar-item--middle";
    };

    return (
      <div className={"plan-calendar"}>
        <div className="calendar-columns-container calendar-header">
          {DAYS.map((day, index) => (
            <p
              className={classNames("calendar-day", {
                "calendar-columns-items": index,
              })}
            >
              {t(`days.${day}`)}
            </p>
          ))}
        </div>
        <div className="calendar-columns-container calendar-tasks">
          {isLoading && (
            <div className="calendar-loader-overlay">
              <PageContentLoader backgroundOpacity={0.5} />
            </div>
          )}
          {DAYS.map((day, dayIndex) => {
            const tasksForDay = planTasks.filter((task) => task.day === DAYS[dayIndex]);
            const maxPosition = tasksForDay.reduce((max, task) => Math.max(max, task.position), 0);
            let taskSlots = Array.from({ length: maxPosition  }, () => null);
            tasksForDay.forEach((task) => {
              taskSlots[task.position] = task;
            });

            return (
              <div className={dayIndex && "calendar-columns-items"}>
                <div
                  key={day + "-calendar-column"}
                  className="calendar-column"
                  onMouseOver={() => viewAddBtn(dayIndex)}
                  onMouseLeave={viewAddBtn}
                >
                  {taskSlots.map((task, index) => 
                    task ? (
                      <CalendarTask
                        key={task.id}
                        id={task?.DailyTask?.id}
                        task={task.DailyTask ?? task}
                        className={`calendar-item ${getTaskClass(task, dayIndex, weekIndex, weeklySchedules.length)}`}
                        includeInDays={planTasks
                          ?.filter((pt) => pt.dailyTaskId === task.dailyTaskId)
                          .map((dt) => dt.day)}
                        rescheduling={task.rescheduling ?? true}
                        planned={{ remindHour: task.remindHour, remindMinute: task.remindMinute }}
                        locked={{}}
                        lockType={null}
                        day={task.day}
                        activityId={task.id}
                        replaceTaskToDay={replaceTaskToDay}
                        replaceScenarioToLock={replaceScenarioToLock}
                        deleteTask={deleteTask}
                        updateTaskNotificationTime={updateTaskNotificationTime}
                        tasksList={tasksList}
                        categories={categories}
                        updateReschedule={updateReschedule}
                        updateDuration={updateDuration}
                        duration={task.duration}
                        setActiveId={toggleOpen}
                        span={task.duration}
                        style={{ "--task-color": task.DailyTask?.backgroundColor || "#ffcc00", width: "100%", height: 40 }}
                        showTitleAndTime={showTitleAndTime(task, dayIndex, weekIndex, weeklySchedules.length)}
                        showLockIcon={showLockIcon(task, dayIndex, weekIndex, weeklySchedules.length)}
                      />
                    ) : (
                      <div key={`empty-${day}-${index}`} className="calendar-task-placeholder" />
                    )
                  )}
                  <div
                    className={classNames({
                      "calendar-add-btn--hidden": hoverColumnIndex !== dayIndex,
                    })}
                  >
                    <AddItemComponent
                      items={tasksForHandleSelect(dayIndex)}
                      handleAddTask={(task) => addTask(task, dayIndex, weekIndexRef.current)}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="calendar-columns-container calendar-scenarios">
          {DAYS.map((day, index) => (
            <div
              key={day + "calendar-scenario-column"}
              className={classNames("calendar-scenario-column", {
                "calendar-columns-items": index,
              })}
              onMouseOver={() => viewAddBtn(index + 7)}
              onMouseLeave={viewAddBtn}
            >
              {planScenarios
                ?.filter((planScenario) => planScenario.day === day)
                .sort(
                  (a, b) =>
                    a.remindHour - b.remindHour ||
                    a.remindMinute - b.remindMinute
                )
                .map((scenario) => {
                  let { lockType, lockHours, lockMinutes, remindHour, remindMinute, day, id, rescheduling } =
                    scenario;
                  const planned = { remindHour, remindMinute };
                  let lockDays = 0
                  if (lockHours > 24) {
                    lockDays = Math.floor(lockHours / 24)
                    lockHours = lockHours % 24
                  }
                  const locked = { lockDays, lockHours, lockMinutes };
                  const includeInDays = planScenarios
                    ?.filter((pt) => pt.id === scenario.id)
                    .map((dt) => dt.day);
                  return (
                    <CalendarTask
                      key={id}
                      id={scenario?.Scenario?.id}
                      task={scenario.Scenario ?? scenario}
                      includeInDays={includeInDays}
                      rescheduling={rescheduling ?? true}
                      planned={planned}
                      locked={locked}
                      lockType={lockType}
                      day={day}
                      activityId={id}
                      replaceTaskToDay={replaceScenarioToDay}
                      replaceScenarioToLock={replaceScenarioToLock}
                      deleteTask={deleteScenario}
                      updateTaskNotificationTime={
                        updateScenarioNotificationTime
                      }
                      updateScenarioLockTime={updateScenarioLockTime}
                      tasksList={tasksList}
                      categories={categories}
                      updateReschedule={updateScenarioReschedule}
                      setActiveId={toggleOpen}
                      showTitleAndTime={true}
                      showLockIcon={true}
                    />
                  );
                })}
              <div
                className={classNames({
                  "calendar-add-btn--hidden": hoverColumnIndex !== index + 7,
                })}
              >
                <AddItemComponent
                  isScenario
                  items={scenariosForHandleSelect(index)}
                  handleAddTask={(scenario) =>
                    addScenario(
                      scenario,
                      index,
                      planScenarios
                        .filter((planScenario) => planScenario.day === day)
                        .sort(
                          (a, b) =>
                            a.remindHour - b.remindHour ||
                            a.remindMinute - b.remindMinute
                        )
                    )
                  }
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }, [
    planTasks,
    hoverColumnIndex,
    tasksList,
    planId,
    scenariosList,
    planScenarios,
  ]);

  return (
    <div className="calendar">
      <style>
        {id
          ? `
              .calendar-item{
                opacity:0.4;
              }
              [data-id="${id}"]{
                opacity:1;
              }
            `
          : ""}
      </style>
      {calendarDaysRender}
    </div>
  );
};

export default PlanCalendar;
